import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import ClientLayout from "../../components/ClientLayout";

import logo from "../../../static/themes/hyped/assets/images/landing/imco-logo.png";
import video from "../../../static/themes/hyped/assets/images/landing/imco-video.mp4";

export const query = graphql`
  {
    logo: contentfulMedia(slug: { eq: "site-logo" }) {
      media {
        fluid(maxHeight: 160, maxWidth: 1194) {
          src
        }
      }
      slug
    }
  }
`;

export default function ClientIMCO({data}) {
  const splitRef = useRef();

  useEffect(() => {
    const target = splitRef.current.querySelectorAll('.letter-split');
    window.Splitting({ target: target, by: "chars" });
  }, [])


  return (
    <ClientLayout>
      <div className="container-client">
        <div className="logo">
          <a className="ajax-link" data-type="page-transition" href="/">
            <img
              src={data.logo.media.fluid.src}
              className="archive-img-fluid"
              alt={data.logo.slug}
            />
          </a>
        </div>

        <div className="flex justify-space-between mt-50 sec-container">
          <div className="flex flex-col justify-center left-client">
            <img
              src={logo}
              className="sec-logo"
              alt="IMCO"
            />
            <span className="web-title">Website Concept</span>

            <div ref={splitRef}>
              <a
                href="mailto:gb@hyped.com"
                className="contact-button btn"
                target="_blank" rel="noreferrer"
              >
                <span className="link-hover letter-split">GET IN TOUCH</span>
                <span className="link-text letter-split">GET IN TOUCH</span>
              </a>
            </div>
          </div>

          <div className="web-video">
            <video
                preload="none"
                playsInline
                loop
                muted
                autoPlay
                controls
                className="web-video-content"
                alt="variis_video"
              >
                <source src={video} type="video/mp4" />
              </video>
          </div>
        </div>
      </div>
    </ClientLayout>
  );
}
