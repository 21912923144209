import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import favicon from '../images/HYPED_Favicon.png'

import "../css/archive/theme.scss";
import '../css/archive/clientlp.scss'

export default function ClientLayout({ children }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  return (
    <div className="hyped-layout">
      <Helmet
        title={site.siteMetadata.title}
        defaultTitle={site.siteMetadata.title}>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>

      <main>
        <div className="cd-index cd-main-content">
          <div id="page-content" data-bgcolor="#fff">
            
            <div className="archive-main-container">
              <div id="app"></div>
              {children}
            </div>
          </div>
        </div>
      </main>
     
    </div>
  );
}
